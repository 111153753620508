.dotList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;

  progress.active {

    border-radius: 8px;
    width: 40px;
    height: 8px;
    margin-left: 4px;

    &::-webkit-progress-bar {
      background-color: var(--medium-grey);
      border-radius: 8px;
    }

    &::-webkit-progress-value {
      background-color: var(--midnight);
      border-radius: 8px;
    }

    &::-moz-progress-bar {
      background-color: var(--midnight);
      border-radius: 8px;
    }

  }

  li {
    margin: 0;
    width: 16px;

    button {
      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        position: initial;
        color: var(--midnight) !important;
        font-size: 8px;
      }
    }
  }
}

.arrow {
  z-index: 1;
  position: absolute;
  top: 50%;
  display: block;
  transform: translate(0, -50%);
  cursor: pointer;
  width: 32px;
  height: 48px;
  flex-grow: 0;
  padding: 17px 10px 17px 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);

  svg {
    width: 14px;
    height: 14px;
  }
}

.next {
  left: 97%;
  top: 108%;
}

.prev {
  left: 93%;
  top: 108%;
}

iframe.window {
  width: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

}
